import React from 'react'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import FadeInWhenVisible from '../components/fadeInWhenVisible'
import Cta from '../components/cta'
import StorageProducts from '../components/storageProducts'

const StorageTemplate =  ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}          
          type="root"
        />
      </div>

      <section className="bg-white">
        <FadeInWhenVisible>
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                {post.frontmatter.bodytitle &&
                  <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                    {post.frontmatter.bodytitle}
                  </h2>
                }
                <div
                  className="mt-3 richtext divlink"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </FadeInWhenVisible>
      </section>

      {post.frontmatter.section &&
        <FadeInWhenVisible>
          {post.frontmatter.section.sort((a, b) => a.sort == b.sort ? 0 : a.sort < b.sort ? -1 : 1).map((sections, index) => (
            <StorageProducts key={index} id={index} platform={sections} />
          ))}
        </FadeInWhenVisible>
      }

      <Cta
        ctatitle={post.frontmatter.cta.title}
        ctadescription={post.frontmatter.cta.description}
        ctalink={post.frontmatter.cta.link}
        ctalinktext={post.frontmatter.cta.linktext}
      />
    </Layout>
  )
}
export default StorageTemplate

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "storage" } }
    ) {
      fields {
        slug
      }
      html
      frontmatter {
        meta {
          title
          description
        }
        title
        subtitle
        bodytitle
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        alttext
        ctatext
        ctaslug
        section {
          title
          sort
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 90) 
            }
          }
          alttext
          sectionproduct {
            subtitle
            text
          }          
          btntext
          btnlink
          doctext
          document {
            publicURL
          }
        }
        cta {
          title
          description
          linktext
          link
        }
      }
    }
  }
`